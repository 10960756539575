import React, { useCallback, useEffect } from "react";
import { makeStyles } from "tss-react/mui";

import { withPixelLineHeight } from "../../../base/styles/functions.web";
import { IDisplayProps } from "../ConferenceTimer";
import request, { Base_URL } from "../../../../../utils/request";
import { useLockFn } from "ahooks";
import { JitsiRecordingConstants } from "../../../base/lib-jitsi-meet";
import { getQueryString } from "../../../../../utils";

const useStyles = makeStyles()((theme) => {
  return {
    timer: {
      ...withPixelLineHeight(theme.typography.labelRegular),
      color: theme.palette.text01,
      padding: "6px 8px",
      backgroundColor: "rgba(0, 0, 0, 0.8)",
      boxSizing: "border-box",
      height: "28px",
      borderRadius: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`,
      marginRight: "2px",

      "@media (max-width: 300px)": {
        display: "none",
      },
    },
  };
});

/**
 * Returns web element to be rendered.
 *
 * @returns {ReactElement}
 */
export default function ConferenceTimerDisplay({
  timerValue,
  timerDurationValue,
  textStyle: _textStyle,
}: IDisplayProps) {
  const { classes } = useStyles();

  const invokeApi = useLockFn(async () => {
    const lessonId = getQueryString("lessonId"); //localStorage.getItem("lessonId");
    const courseId = getQueryString("courseId"); //localStorage.getItem("courseId");
    // const userId = getQueryString("uid"); //localStorage.getItem("aid");
    const userId = getQueryString("uid") || getQueryString("aid"); //localStorage.getItem("aid");
    request.post(`/course/video/${courseId}/${lessonId}/${userId}`, {
      openVideoDate: new Date(),
    });
    request.put(`/course/video/lessonstudent/${courseId}/${lessonId}`, {
      courseId,
      userId,
      lessonStatus: "gk85fda43441416a9a92eb61c136b5bc",
    });
    // if (localStorage.getItem("teacher") == "true") {
    //   //@ts-ignore
    //   const state = APP.store.getState();
    //   const { conference } = state["features/base/conference"];
    //   conference?.startRecording({
    //     mode: JitsiRecordingConstants.mode.FILE,
    //     appData: JSON.stringify({
    //       file_recording_metadata: {
    //         share: true,
    //       },
    //     }),
    //   });
    // }
    window.addEventListener("beforeunload", (event: any) => {
      // try {
      //   const { sessionDatas } = state["features/recording"];
      //   conference?.stopRecording(sessionDatas[0].id);
      // } catch {}

      // 显示确认框
      const confirmationMessage = "Are you sure want to leave?";
      event.returnValue = confirmationMessage; // 标准浏览器支持此属性
      const value = document.getElementById("timerDurationValue")?.innerText;
      fetch(Base_URL + `/course/video/${courseId}/${lessonId}/${userId}`, {
        headers: {
          "Content-Type": "application/json", // 设置请求头为 JSON 格式
        },
        body: JSON.stringify({
          classDuration: value,
        }),
        method: "post",
        keepalive: true,
      });
    });
  });

  useEffect(() => {
    invokeApi();
  }, []);

  return (
    <>
      <span id="timerDurationValue" style={{ display: "none" }}>
        {timerDurationValue}
      </span>
      <span className={classes.timer}>{timerValue}</span>
    </>
  );
}
